/* Globales */
body {
  background-color: rgb(15, 15, 15) !important;
  color: white !important;
}

.cont {

  display: flex;
  justify-content: center;
  align-items: center;
}

.contesp {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 78%;
}



/* NavBar */

.navbs {
  background-color: black !important;
  color: white !;
}

.navbs a {
  color: white !important;
}

/* Eslogan */

.bienv {
  background-color: rgb(0, 0, 0);
}

.eslogan {
  position: absolute;
  top: 15%;
  font-size: 50px;
  font-family: 'Times New Roman', Times, serif;
  left: 5%;
}
.eslogan2 {
  position: absolute;
  top: 25%;
  font-size: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  left: 5%;
}

@media (max-width: 1025px) {
.robotcont{
  width: 100%;
  height: 100vh;
}
.robot{
  width: 100%;
  height: 100%;
}
  .eslogan {
    position: absolute;
    top: 8%;
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    left: 5%;
  }

  .eslogan2 {
    position: absolute;
    top: 20%;
    font-size: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    left: 5%;
  }
  


} 
@media (max-width: 769px) {

  .robotcont{
    width: 100%;
    height: 100vh;
  }
  .robot{
    width: 100%;
    height: 100%;
  }
    .eslogan {
      position: absolute;
      top: 10%;
      font-size: 30px;
      font-family: 'Times New Roman', Times, serif;
      left: 5%;
      text-align: center;
    }
  
    .eslogan2 {
      position: absolute;
      top: 20%;
      font-size: 15px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      left: 5%;
    }
  
  
}

@media (max-width: 426px) {

  .robotcont{
    width: 100%;
    height: 100vh;
  }
  .robot{
    width: 100%;
    height: 100%;
  }
    .eslogan {
      position: absolute;
      top: 10%;
      font-size: 30px;
      font-family: 'Times New Roman', Times, serif;
      left: 5%;
      text-align: center;
    }
  
    .eslogan2 {
      position: absolute;
      top: 18%;
      font-size: 15px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      left: 5%;
    }
  
  
}
@media (max-width: 376px) {


  .robot{
    width: 100%;
    height: 100%;
  }
  .eslogan {
    position: absolute;
    top: 10%;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    left: 5%;
    text-align: center;
  }

  .eslogan2 {
    position: absolute;
    top: 16%;
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    left: 5%;
  }
  
  
  
  
}
@media (max-width: 321px) {


  .robot{
    width: 100%;
    height: 100%;
  }
  .eslogan {
    position: absolute;
    top: 10%;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    left: 5%;
    text-align: center;
  }

  .eslogan2 {
    position: absolute;
    top: 15%;
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    left: 5%;
  }
  
  
  
  
}

/* Trabajo */


.h2sty {
  font-size: 40px;
  margin: 20px;
  text-align: center;
  font-family: 'Vogue';
}

.imgcont {
  width: 100%;
  padding: 10px;
  transition: all 500ms ease-out;
}



.contenedor {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
}

.contenedor figure {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  margin: 5px;
}

.contenedor figure img {
  width: 100%;
  height: 100%;
  transition: all 500ms ease-out;
}

.contenedor figure .capa {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #c5c82ec0;
  transition: all 500ms ease-out;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  color: black;
  font-family: monospace;
  padding-top: 25%
}


.contenedor figure:hover>.capa {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1024px) {}

@media (max-width: 426px) {
  .contenedor {
    height: 100%;
  }

  .contenedor figure {
    margin: 1px;
  }

  .contenedor figure img {
    width: 120%;
    height: 20vh;
  }

  .contenedor figure .capa h3 {
    font-size: 20px;
    padding-top: 0%
  }

  .contenedor figure .capa {
    font-size: 10px;
    padding-top: 0%
  }

  .contenedor figure .capa .abog {
    font-size: 14px;
    padding-top: 0%
  }


}

@media (max-width: 376px) {
  .contenedor {
    height: 20vh;
  }

  .h2sty {
    font-size: 25px;
  }

  .contenedor figure {
    margin: 1px;
  }

  .contenedor figure img {
    width: 100%;
    height: 100%;
  }

  .abj {
    width: 150% !important;
  }

  .contenedor figure .capa {
    padding-top: 0%
  }

  .contenedor figure .capa .abog {
    font-size: 13px;
    padding-top: 0%
  }




}

@media (max-width: 321px) {


  .contenedor figure {
    margin: 1px;
  }

  .contenedor figure .capa h3 {
    font-size: 14px;
    padding-top: 0%
  }

  .contenedor figure .capa {
    font-size: 10px;
    padding-top: 0%
  }

  .contenedor figure .capa .abog {
    font-size: 11px;
    padding-top: 0%
  }

  .rest {
    display: none;
  }


}

/* Team */
.cont1 {

  display: flex;
  justify-content: center;
  align-items: center;

}

.equipoimg {
  width: 200px;
  height: 200px;
  margin: 5px;
}

.h2team {
  font-size: 20px;
  text-align: center;
}

.h3team {
  font-size: 15px;
  text-align: center;
}

.textoteam {
  border-radius: 30px;
  background: #C6C82E;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 50px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 26px -18px inset;
  color: black;

}

.pteam {
  font-size: 20px;
  letter-spacing: 1px;
  padding: 25px;
  font-family: 'Trebuchet MS';
}

@media (max-width: 1025px) {

  .pteam {
    font-size: 20px;
    letter-spacing: 0px;
    padding: 5px;
  }

}

@media (max-width: 769px) {
  .equipoimg {
    width: 150px;
    height: 150px;
    margin: 2px;
  }

  .h2team {
    font-size: 15px;
  }

  .h3team {
    font-size: 15px;
  }

  .pteam {
    font-size: 15px;
    letter-spacing: 0px;
    padding: 10px;
  }

}

@media (max-width: 426px) {
  .equipoimg {
    width: 100px;
    height: 100px;
    margin: 2px;
  }

  .h2team {
    font-size: 10px;
  }

  .h3team {
    font-size: 10px;
  }

  .pteam {
    font-size: 10px;
    letter-spacing: 0px;
    padding: 5px;
  }

}

/* Paquetes */
.contxd {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.btnn {
  height: 3.5rem;
  width: 13.2rem;
  border-radius: 5px;
  border: 5px solid rgb(255, 255, 255);
  cursor: pointer;
  text-transform: uppercase;
  background-color: rgb(83, 82, 82);
  color: rgb(234, 234, 234);
  font-weight: 700;
  transition: 0.6s;
  box-shadow: 0px 0px 60px #1f4c65;
  -webkit-box-reflect: below 10px linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4));
  margin-bottom: 105px;
}


.texttt {
  font-size: 1.8rem;
  font-family: Arial, Helvetica, sans-serif;
}

.btnn:hover {
  background-color: rgb(47, 41, 41);
  border-radius: 0;
}

.btnn a {
  text-decoration: none;
  color: white;
}



/*Cards  */

.outer {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  padding: 1px;
  background: radial-gradient(circle 230px at 0% 0%, #ffffff, #0c0d0d);
  position: relative;
}

.dot {
  width: 5px;
  aspect-ratio: 1;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 10px #ffffff;
  border-radius: 100px;
  z-index: 2;
  right: 10%;
  top: 10%;
  animation: moveDot 6s linear infinite;
}


@keyframes moveDot {

  0%,
  100% {
    top: 5%;
    right: 10%;
  }

  25% {
    top: 5%;
    right: calc(100% - 40px);
  }

  50% {
    top: calc(100% - 35px);
    right: calc(100% - 40px);
  }

  75% {
    top: calc(100% - 35px);
    right: 10%;
  }
}



.card {
  z-index: 1;
  width: 100%;
  height: 100% !important;
  border-radius: 9px;
  border: solid 1px #202222;
  background-size: 20px 20px;
  background: radial-gradient(circle 280px at 0% 0%, #444444, #0c0d0d);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  color: #fff;
}

.ray {
  width: 50%;
  height: 10%;
  border-radius: 100px;
  position: absolute;
  background-color: #c7c7c7;
  opacity: 0.4;
  box-shadow: 0 0 50px #fff;
  filter: blur(10px);
  transform-origin: 10%;
  top: 0%;
  left: 0;
  transform: rotate(40deg);
}

.card .textbronce {
  font-weight: bolder;
  font-size: 4rem;
  background: linear-gradient(45deg, #000000 4%, #CE8946, #000);
  background-clip: text;
  color: transparent;
}

.card .textplata {
  font-weight: bolder;
  font-size: 4rem;
  background: linear-gradient(45deg, #000000 4%, #fff, #000);
  background-clip: text;
  color: transparent;
}

.card .textoro {
  font-weight: bolder;
  font-size: 4rem;
  background: linear-gradient(45deg, #000000 4%, #FFBF00, #000);
  background-clip: text;
  color: transparent;
}

.line {
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: #2c2c2c;
}

.topl {
  top: 5%;
  background: linear-gradient(90deg, #888888 30%, #1d1f1f 70%);
}

.bottoml {
  bottom: 5%;
}

.leftl {
  left: 10%;
  width: 1px;
  height: 100%;
  background: linear-gradient(180deg, #747474 30%, #222424 70%);
}

.rightl {
  right: 10%;
  width: 1px;
  height: 100%;
}

.int {
  width: 200%;
  height: 500px;
  margin: 15px;
}

.check {
  color: greenyellow;
}

.cont2 {
  height: 50%;
  color: white;
}

.conti {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
}


@media (max-width: 1441px) {


  .conti {
    width: 100% !important;
    height: 100% !important;
  }

  .contxd {

    height: 90vh;
  }

  .card {
    height: 100% !important;
  }

  .outer {
    width: 100%;
    height: 100%;
  }

  @keyframes moveDot {

    0%,
    100% {
      top: 5%;
      right: 10%;
    }

    25% {
      top: 5%;
      right: calc(100% - 45px);
    }

    50% {
      top: calc(100% - 45px);
      right: calc(100% - 40px);
    }

    75% {
      top: calc(100% - 35px);
      right: 10%;
    }
  }
}

@media (max-width: 1025px) {


  .contxd {

    height: 100vh;
  }

 



  @keyframes moveDot {

    0%,
    100% {
      top: 5%;
      right: 10%;
    }

    25% {
      top: 5%;
      right: calc(100% - 35px);
    }

    50% {
      top: calc(100% - 35px);
      right: calc(100% - 35px);
    }

    75% {
      top: calc(100% - 30px);
      right: 10%;
    }
  }

}

@media (max-width: 769px) {

  .contxd {
    height: 83vh;
  }

  .btnn {
    height: 3.5rem;
    width: 10.2rem;
  }


  .texttt {
    font-size: 1.5rem;
  }

  @keyframes moveDot {

    0%,
    100% {
      top: 5%;
      right: 10%;
    }

    25% {
      top: 5%;
      right: calc(100% - 30px);
    }

    50% {
      top: calc(100% - 35px);
      right: calc(100% - 30px);
    }

    75% {
      top: calc(100% - 30px);
      right: 10%;
    }
  }




  .ray {
    width: 35%;
  }

  .card .textbronce {
    font-size: 3rem;
  }

  .card .textplata {
    font-size: 3rem;
  }

  .card .textoro {
    font-size: 3rem;
  }


  .int {
    width: 200%;
    height: 500px;
    margin: 5px;
  }

  .check {
    color: greenyellow;
  }

  .cont2 {
    font-size: 14px;
  }

  .conti {
    width: 100% !important;
  }


}

@media (max-width: 426px) {
  .contxd {

    height: 65vh;
  }

  .btnn {
    height: 3rem;
    width: 6rem;
  }


  .texttt {
    font-size: 0.9rem;
  }

  @keyframes moveDot {

    0%,
    100% {
      top: 3%;
      right: 3%;
    }

    25% {
      top: 3%;
      right: calc(100% - 5%);
    }

    50% {
      top: calc(100% - 3%);
      right: calc(100% - 3%);
    }

    75% {
      top: calc(100% - 3%);
      right: 3%;
    }
  }

  .line {
    height: 0px;
  }

  .leftl {
    width: 0px;
  }

  .rightl {
    width: 0px;
  }

  .ray {
    width: 35%;
  }

  .card .textbronce {
    font-size: 2rem;
  }

  .card .textplata {
    font-size: 2rem;
  }

  .card .textoro {
    font-size: 2rem;
  }

  .int {
    width: 100%;
    margin: 1px;
  }

  .cont2 {
    font-size: 10px;
  }

  .conti {
    width: 100% !important;
  }


}

/* Contacto */
.inputname {
  border: 5px solid #C6C82E;
  background-color: #C6C82E;
  border-radius: 20px;
  text-align: center;
  width: 100%;
  height: 25px;
  color: black;
}

.inputnum {
  border: 5px solid #C6C82E;
  background-color: #C6C82E;
  border-radius: 20px;
  text-align: center;
  width: 100%;
  height: 25px;
  margin-top: 25px;
  color: black;
}

.inputex {
  border: 5px solid #C6C82E;
  background-color: #C6C82E;
  border-radius: 20px;
  height: 200px;
  width: 100%;
  text-align: center;
  color: black;

}

.inputbut {
  border: 5px solid #C6C82E;
  background-color: #C6C82E;
  border-radius: 20px;
  color: black;
  text-align: center;
  width: 30%;
  height: 40px;
  margin-top: 10px;

}

@media (max-width: 426px) {

  .inputnum {
    margin-top: 0px;
  }

}


@media (max-width: 321px) {

  .inputname {
    height: 30px;
    width: 140px;
  }
}



/* Footer */
.contfot {
  border-top: 0.5px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-top: 20px;
}

.h1fot {
  font-size: 20px;
  font-family: 'Vogue';

}

.redes {
  text-decoration: none;
  font-size: 25px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.h3fot {
  font-size: 15px;
}

.copy {
  font-size: 10px;
  color: grey;
}